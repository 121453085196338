import React from 'react';
import { styles } from './EventTickets.styles';
import styled from '@gf/cross-platform-lib/styled-components';

import { View } from 'react-native';
import { AccessCodeInformation, ProductSeating, Promotion } from '@gf/cross-platform-lib/interfaces';
import { EventTicketItem } from '../EventTicketItem';
import { InputChangeType } from '@gf/cross-platform-lib/components/NumberInput/contants';
import { EventTicketsAccessCodeOnly } from '../EventAccessCode/EventTicketsAccessCodeOnly';
import { AccessCodeType } from '@gf/cross-platform-lib/constants';
import { EventCart } from '@gf/cross-platform-lib/models';

const EventTicketsContainer = styled(View)`
  ${styles.eventTicketsContainer}
`;

interface EventTicketsProps {
  ticketsLeftInEvent?: number;
  onlyAccessCodeTickets: boolean;
  tickets: ProductSeating[];
  accessCodeTickets: ProductSeating[];
  financialSchoolState: string;
  prodIdsToAccessCodes: Map<number, AccessCodeInformation>;
  updateTicketsToQuantity: (ticket: ProductSeating, newQuantity: number, changeType?: InputChangeType) => void;
  ticketsToQuantity: Map<ProductSeating, number>;
  numberInputState: boolean;
  accessCodes?: Promotion[];
  eventDetailCartEvent: EventCart;
  handleUpdateTicketUpdateAt: (ticketId: string) => void;
}

export const EventTickets = ({
  ticketsLeftInEvent,
  onlyAccessCodeTickets,
  tickets,
  accessCodeTickets,
  financialSchoolState,
  prodIdsToAccessCodes,
  updateTicketsToQuantity,
  ticketsToQuantity,
  numberInputState,
  eventDetailCartEvent,
  handleUpdateTicketUpdateAt
}: EventTicketsProps) => {
  return !onlyAccessCodeTickets ? (
    <EventTicketsContainer>
      {tickets.map((ticket: ProductSeating, index) => (
        <EventTicketItem
          key={ticket.id}
          accessCodeType={AccessCodeType.NONE}
          ticket={ticket}
          ticketsLeftInEvent={ticketsLeftInEvent}
          quantity={ticketsToQuantity.get(ticket)}
          updateTicketsToQuantity={updateTicketsToQuantity}
          eventDetailCartEvent={eventDetailCartEvent}
          handleUpdateTicketUpdateAt={handleUpdateTicketUpdateAt}
          financialSchoolState={financialSchoolState}
          isFirstTicket={index === 0}
        />
      ))}
      {accessCodeTickets.map((ticket: ProductSeating, index) => (
        <EventTicketItem
          key={ticket.id}
          accessCode={prodIdsToAccessCodes.get(parseInt(ticket.id))?.code}
          accessCodeType={
            prodIdsToAccessCodes.has(parseInt(ticket.id)) ? AccessCodeType.UNLOCKED : AccessCodeType.LOCKED
          }
          ticket={ticket}
          quantity={ticketsToQuantity.get(ticket)}
          updateTicketsToQuantity={updateTicketsToQuantity}
          numberInputState={numberInputState}
          eventDetailCartEvent={eventDetailCartEvent}
          handleUpdateTicketUpdateAt={handleUpdateTicketUpdateAt}
          financialSchoolState={financialSchoolState}
          isFirstTicket={index === 0}
        />
      ))}
    </EventTicketsContainer>
  ) : (
    <EventTicketsAccessCodeOnly />
  );
};

export default EventTickets;
