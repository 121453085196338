import React from 'react';

import { TicketStatusIcon } from '@gf/cross-platform-lib/components';

import { styles } from './PassInfoItem.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { TicketState } from '@gf/cross-platform-lib/constants';

const Wrapper = styled.View`
  ${styles.wrapper}
`;

const Container = styled.View`
  ${styles.container}
`;

const SubTitleWrapper = styled.View`
  ${styles.subTitleWrapper}
`;

const SubTitle = styled.Text`
  ${styles.subTitle}
`;

const Title = styled.Text`
  ${styles.title}
`;

const Devider = styled.View`
  ${styles.devider}
`;

export type PassInfoItemProps = {
  title: string;
  subTitle: string;
  isTransfer?: boolean;
  hasDivider?: boolean;
};

export const PassInfoItem = ({ title, subTitle, isTransfer = false, hasDivider = false }: PassInfoItemProps) => {
  return (
    <Container>
      <Wrapper>
        <Title numberOfLines={1}>{title}</Title>
        <SubTitleWrapper>
          <TicketStatusIcon status={isTransfer ? TicketState.TRANSFERRED : TicketState.USED} />
          <SubTitle numberOfLines={1}>{subTitle}</SubTitle>
        </SubTitleWrapper>
      </Wrapper>
      {hasDivider && <Devider />}
    </Container>
  );
};
