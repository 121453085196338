import React from 'react';

import { Carbonicons } from '@gf/cross-platform-lib/components';

import { styles } from './PassInfo.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { ProductSeating } from '@gf/cross-platform-lib/interfaces';
import isEmpty from 'lodash/isEmpty';
import { PassInfoItemList } from '../PassInfoItemList';

const PassInfoContainer = styled.View`
  ${styles.passInfoContainer}
`;

const TitleWrapper = styled.View`
  ${styles.titleWrapper}
`;

const Title = styled.Text`
  ${styles.title}
`;

const SubTitle = styled.Text`
  ${styles.subTitle}
`;

const SubTitleWrapper = styled.View`
  ${styles.subTitleWrapper}
`;

const DigitalPassWrapper = styled.View`
  ${styles.digitalPassWrapper}
`;

export type PassInfoProps = {
  transferAllowed?: boolean;
  transferLimit?: number;
  redemptionAllowed?: boolean;
  ticketTypes?: ProductSeating[];
};

export const PassInfo = ({ transferAllowed, transferLimit, redemptionAllowed, ticketTypes }: PassInfoProps) => {
  if (isEmpty(ticketTypes)) {
    return <></>;
  }

  return (
    <PassInfoContainer>
      <TitleWrapper>
        {!transferAllowed && !redemptionAllowed ? (
          <Title>Passes disabled</Title>
        ) : (
          <>
            <Title>Passes use and transfer limit:</Title>
            <PassInfoItemList
              transferAllowed={transferAllowed}
              transferLimit={transferLimit}
              redemptionAllowed={redemptionAllowed}
              ticketTypes={ticketTypes}
            />
          </>
        )}
      </TitleWrapper>
      <SubTitleWrapper>
        <DigitalPassWrapper>
          <Carbonicons color='white' name='digitalPass' size={14} />
        </DigitalPassWrapper>
        <SubTitle>Pass settings are set by the school.</SubTitle>
      </SubTitleWrapper>
    </PassInfoContainer>
  );
};

export default PassInfo;
