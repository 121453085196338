import React from 'react';

import styled from '@gf/cross-platform-lib/styled-components';
import { Carbonicons } from './Carbonicons';
import { CheckmarkFilled, CloseFilled, TurnRight } from './Carbonicons/collections';

const Wrapper = styled.View`
  height: 20px;
  width: 20px;
`;

enum TicketState {
  'AVAILABLE',
  'USED',
  'TRANSFERRED',
  'REFUNDED',
  'TRANSFER_UNAVAILABLE'
}

const StatusIconMaps = {
  [TicketState[TicketState.TRANSFERRED]]: TurnRight,
  [TicketState[TicketState.USED]]: CheckmarkFilled,
  [TicketState[TicketState.TRANSFER_UNAVAILABLE]]: CloseFilled
};

interface TicketStatusIconProps {
  status: TicketState;
  inverted?: boolean;
  ticketIconSize?: number;
  statusIconSize?: number;
  justTransferred?: boolean;
}

export const TicketStatusIcon = ({
  status,
  inverted,
  ticketIconSize,
  statusIconSize,
  justTransferred
}: TicketStatusIconProps) => {
  let StatusIcon = StatusIconMaps[TicketState[status]];
  if (status === TicketState.AVAILABLE) {
    StatusIcon = StatusIconMaps[TicketState[TicketState.TRANSFERRED]];
  }

  const color = (function () {
    if (status === TicketState.TRANSFER_UNAVAILABLE) return inverted ? '#FFF' : '#DA1E28';
    if (justTransferred) return '#06a000';
    return inverted ? '#FFF' : '#323232';
  })();

  const figurecolor = (function () {
    if (status === TicketState.TRANSFER_UNAVAILABLE) return inverted ? '#DA1E28' : '#FFF';
    return inverted ? '#323232' : '#FFF';
  })();

  return (
    <Wrapper>
      <Carbonicons name='ticket' color={color} size={ticketIconSize ? ticketIconSize : 16} />
      {StatusIcon ? (
        <StatusIcon
          color={color}
          figurecolor={figurecolor}
          width={statusIconSize ? statusIconSize : 12}
          height={statusIconSize ? statusIconSize : 12}
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent',
            borderRadius: 5
          }}
        />
      ) : null}
    </Wrapper>
  );
};
