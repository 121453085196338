import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  passInfoContainer: css`
    align-items: center;
    border-radius: 4px;
    border: 1px solid #0043ce;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 16px;
    padding: 16px;
    width: 100%;
    gap: 8px;
    background-color: #dde4f6;
  `,
  titleWrapper: css`
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
  `,
  title: css`
    color: #191919;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
  `,
  subTitleWrapper: css`
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  subTitle: css`
    color: #4a4a4b;
    font-family: Rubik-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
  `,
  digitalPassWrapper: css`
    padding: 3px;
    padding-top: 2px;
  `,
  transferWrapper: css`
    flex-direction: row;
    gap: 8px;
  `
};
