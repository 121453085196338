import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  wrapper: css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 6px;
    background-color: #dde4f6;
  `,
  container: css`
    display: flex;
    flex-direction: row;
    gap: 8px;
    max-width: 158px;
  `,
  title: css`
    color: #191919;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    max-width: 145px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  subTitle: css`
    color: #191919;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    padding-left: 8px;
  `,
  subTitleWrapper: css`
    width: 100%;
    flex-direction: row;
  `,
  devider: css`
    width: 1px;
    background-color: rgba(0, 0, 0, 0.16);
  `
};
