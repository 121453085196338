import React from 'react';

import { styles } from './PassInfoItemList.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import isNumber from 'lodash/isNumber';
import { capitalizeFirstChar } from '@gf/cross-platform-lib/utils';
import { PassInfoItem } from '../PassInfoItem';
import { ProductSeating } from '@gf/cross-platform-lib/interfaces';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import isEmpty from 'lodash/isEmpty';

const InfoWrapper = styled.View`
  ${styles.infoWrapper}
`;

export type PassInfoItemListProps = {
  transferAllowed?: boolean;
  transferLimit?: number;
  redemptionAllowed?: boolean;
  ticketTypes?: ProductSeating[];
};

export const getTitle = (titleText: string, allowed?: boolean, limit?: number) => {
  const formatedTitleText = capitalizeFirstChar(`${titleText}s`);
  let title = `${formatedTitleText} disabled`;
  if (allowed && isNumber(limit)) {
    if (limit === 1) {
      title = `Single ${titleText}`;
    } else if (limit > 1) {
      title = `${limit} ${titleText}s${titleText === 'transfer' ? ' max' : ''}`;
    }
  } else if (allowed) {
    title = `Unlimited ${titleText}s`;
  }
  return title;
};

const PASS_INFO_BREAK_POINT_WIDTH = 608;
const PASS_INFO_WIDTH = 576;
const PASS_ITEM_WIDTH = 158;

export const PassInfoItemList = ({
  transferAllowed,
  transferLimit,
  redemptionAllowed,
  ticketTypes
}: PassInfoItemListProps) => {
  const { width } = useMediaQuery();
  if (isEmpty(ticketTypes)) {
    return <></>;
  }
  const listItemsWidth = width > PASS_INFO_BREAK_POINT_WIDTH ? PASS_INFO_WIDTH : width - 66;
  const maxItemsPerRow = Math.floor(listItemsWidth / PASS_ITEM_WIDTH) || 1;
  const numOfRow = Math.floor((ticketTypes?.length ?? 0) / maxItemsPerRow) + 1;

  return Array.from({ length: numOfRow }, (_, index) => {
    const count = index * maxItemsPerRow;
    const items = ticketTypes
      ?.slice(count, count + maxItemsPerRow)
      .map((ticketType, itemIndex) => (
        <PassInfoItem
          key={`pass-info-item-${ticketType.id}`}
          title={ticketType.name}
          subTitle={getTitle('use', redemptionAllowed, ticketType.redemptionLimit)}
          hasDivider={itemIndex !== maxItemsPerRow - 1}
        />
      ));

    return (
      <InfoWrapper key={`pass-info-wrapper-${index}`}>
        {items}
        {index === numOfRow - 1 && (
          <PassInfoItem
            key='pass-transfer-item'
            title='All passes'
            subTitle={getTitle('transfer', transferAllowed, transferLimit)}
            isTransfer
          />
        )}
      </InfoWrapper>
    );
  });
};

export default PassInfoItemList;
