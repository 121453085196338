import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  infoWrapper: css`
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    padding-bottom: 8px;
  `
};
