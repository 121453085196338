import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { Platform } from 'react-native';

const IconRotatedWrapper = styled.View`
  align-items: center;
  display: flex;
  rotate: 45deg;
`;

export interface RotateIconProps {
  children: React.ReactNode;
  degrees?: string;
}

export const RotateIcon = ({ children, degrees }: RotateIconProps) => {
  return (
    <IconRotatedWrapper
      style={
        Platform.OS !== 'web'
          ? {
              transform: [{ rotate: degrees ?? '45deg' }]
            }
          : {}
      }
    >
      {children}
    </IconRotatedWrapper>
  );
};
